import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Careers from 'pages/Home/Careers';

import * as S from 'styles/Home/styled';
import { Container } from 'styles';
import PageNotFoundImage from 'images/pagina-nao-encontrada.svg';


const StyledFigure = styled.figure`
  margin-bottom: 16px;
  text-align: center;
`;

const NotFoundPage = () => {
  const documentGlobal = typeof document !== 'undefined' && document;

  useEffect(() => {
    const headerId = documentGlobal.getElementById('VG_HEADER');
    headerId.classList.add('vg-header__404-page');
  }, [documentGlobal]);

  return (
    <Layout>
      <SEO
        title="Página não encontrada | Vagas de Emprego e Oportunidades de Trabalho - Busca de Emprego | Vagas.com"
        description="As melhores vagas no mercado de tecnologia das melhores empresas estão no Vagas.com. Candidate-se gratuitamente e cresça no mercado de tecnologia agora!"
      />
      <S.StyledSection>
        <Container>
          <S.SectionTitle>Desculpe, parece que esta página está incorreta ou não existe. Veja o que fazer agora:</S.SectionTitle>
          <StyledFigure>
            <img src={PageNotFoundImage} alt="404 - Página não encontrada" />
          </StyledFigure>
          <S.SectionParagraph>
            Acesse nossa página de <a className="link-default" href="https://www.vagas.com.br/cargo">cargos</a> e navegue pelos mais de 8.000 cargos apresentados.
          </S.SectionParagraph>
          <S.SectionParagraph>
            Volte para nossa pesquisa de <a className="link-default" href="https://www.vagas.com.br">vagas</a> e encontre oportunidades de emprego na sua área.
          </S.SectionParagraph>
        </Container>
        <Careers />
      </S.StyledSection>
    </Layout>
  )
};

export default NotFoundPage;
